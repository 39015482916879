<template>
  <div class="customer-care-pages fashion-expert-page">
    <b-row class="layout-with-shoulder">
      <b-col cols="12" class="shoulder-left">
        <div class="stiky-position">
          <customer-care-link />
        </div>
      </b-col>
      <b-col cols="12" class="shoulder-content">
        <section class="fashion-expert">
          <no-ssr>
          <header class="header">
            <h1 class="title">{{$t('fashionExpert.title')}}</h1>
            <div class="full-background" ><img :src="personalShopper()" /></div>
            <p class="subtitle">{{$t('fashionExpert.notice')}}</p>
          </header>
        </no-ssr>
          <section class="content">
            <b-form ref="fashionExpertForm" data-vv-scope="fashionExpert"  @submit.prevent="onSubmit" novalidate>
              <b-row no-gutters class="custom-row">
                <b-col class="pr-md-2 custom-col" md="6">
                  <b-form-group id="groupFirstname">
                    <base-input
                      type="text"
                      name="firstName"
                      v-model="form.firstName"
                      v-validate="'required'"
                      :class="[{'is-invalid': errors.has('fashionExpert.firstName')}, 'secondary']"
                      :label="$t('fashionExpert.firstName')+'*'"
                      :validations="[{
                          condition: errors.has('fashionExpert.firstName'),
                          text: errors.first('fashionExpert.firstName')
                      }]">
                    </base-input>
                  </b-form-group>
                </b-col>
                <b-col class="pl-md-2 custom-col" md="6">
                  <b-form-group id="groupLastname">
                    <base-input
                      type="text"
                      name="lastName"
                      v-model="form.lastName"
                      v-validate="'required'"
                      :class="[{'is-invalid': errors.has('fashionExpert.lastName')}, 'secondary']"
                      :label="$t('fashionExpert.lastName')+'*'"
                      :validations="[{
                        condition: errors.has('fashionExpert.lastName'),
                        text: errors.first('fashionExpert.lastName')
                      }]">
                    </base-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row no-gutters class="custom-row">
                <b-col class="pr-md-2 custom-col" md="6">
                  <b-form-group id="groupEmail">
                    <base-input
                      type="email"
                      name="email"
                      v-model="form.email"
                      v-validate="'required|email'"
                      :class="[{'is-invalid': errors.has('fashionExpert.email')}, 'secondary']"
                      :label="$t('fashionExpert.email')+'*'"
                      :validations="[{
                        condition: errors.has('fashionExpert.email'),
                        text: errors.first('fashionExpert.email')
                      }]">
                    </base-input>
                  </b-form-group>
                </b-col>
                <b-col class="pl-md-2 custom-col" md="6">
                  <b-form-group id="groupCountry">
                    <base-select
                      name="country"
                      v-model="form.country"
                      :label="$t('fashionExpert.selectCountry')+'*'"
                      :options="getCountriesOptions"
                      :clearable="false"
                      v-validate="'required'"
                      :class="[{'is-invalid': errors.has('fashionExpert.selectCountry')}, 'secondary']"
                      :searchable="true"
                      :validations="[{
                        condition: errors.has('fashionExpert.selectCountry'),
                        text: errors.first('fashionExpert.selectCountry')
                      }]">
                    </base-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row no-gutters class="custom-row">
                <b-col class="custom-col base-textarea-col" md="12">
                  <b-form-group id="groupMessage">
                    <base-textarea
                      name="message"
                      v-model="form.message"
                      :label="$t('fashionExpert.message')+'*'"
                      :rows="12"
                      v-validate="{required: true, max: 230}"
                      :validations="[{
                        condition: errors.has('fashionExpert.message'),
                        text: $t('fashionExpert.errorMessageTxt')
                      }]">
                    </base-textarea>
                    <div class="base-textarea-footer">
                      <p class="privacy-policy d-none d-md-inline" v-html="getPrivacyPolicy"></p>
                      <p class="total-chars">{{ $t('fashionExpert.charactersAvailable') }} {{ totalcount }} </p>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="form-footer">
                <b-form-group id="groupUploadFile" class="upload-file-label">
                  <label class="mb-0 custom-file-upload btn btn-secondary">
                    <input type="file" class="d-none" @change="processFile($event)" :accept="acceptFile"/>
                    <span class="upload-intro f-regular">
                      {{$t('fashionExpert.attachFile')}}
                    </span>
                  </label>
                  <ul v-if="form.attachments.length > 0" class="files-list-text list-group">
                    <li v-for="(file, index) in form.attachments" :key="file.lastModified" class="filename-list list-group-item">
                      <button @click="removeFile(index)" class="btn btn-none">
                        <i class="icomoon-icon icon-x"></i>
                      </button>
                      <span class="file-name">{{file.name}}</span>
                    </li>
                  </ul>
                  <p class="upload-file-notice">{{$t('fashionExpert.uploadFileNotice')}}</p>
                </b-form-group>
                <p class="privacy-policy d-inline d-md-none" v-html="getPrivacyPolicy"></p>
                <button type="submit" class="btn btn-primary">{{$t('fashionExpert.send')}}</button>
              </div>
            </b-form>
          </section>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { toast } from '@/modules/notifications'
import { pathTranslate } from '@/helpers/routeHelpers'
import CustomerCareLink from 'theme/components/CustomerCare/CustomerCareLink.vue'
import BaseSelect from 'theme/components/Form/BaseSelect'
import BaseInput from 'theme/components/Form/BaseInput'
import BaseTextarea from 'theme/components/Form/BaseTextarea'
import Logger from '@/services/Logger'
// import { isMobile } from '@/helpers/utils'

export default {
  inject: ['$validator'],
  data () {
    return {
      acceptFile: 'image/*,.docx,.pdf,.txt,.xls,.jpg,.jpeg,.gif,.png',
      form: {
        country: '',
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        attachments: [],
        attachmentsInBase64: []
      }
    }
  },
  computed: {
    getAttachementToSend () {
      if (this.form.attachmentsInBase64.length > 0) {
        return this.form.attachmentsInBase64[0].base64
      }
      return ''
    },
    getCountries () {
      return this.$store.getters['ui/getCountries']
    },
    getCountriesOptions () {
      if (this.getCountries && Object.keys(this.getCountries).length > 0) {
        return Object.values(this.getCountries).reduce((prev, next) => {
          /* remove duplicare in array with new Set */
          let countriesName = [...new Set(next.map(country => country.country_name))]
          return prev.concat(countriesName)
        }, [])
      }
      return []
    },
    getPrivacyPolicy () {
      let linkPrivacy = this.$router.resolve({ name: 'factoryPage', params: { slug: pathTranslate({ path: 'privacy' }), lang: this.$store.state.ui.urlCode } }).href
      return this.$t('fashionExpert.privacyPolicy', { linkPrivacy })
    },
    totalcount () {
      let maxCount = 230
      maxCount = maxCount - this.form.message.length
      return maxCount >= 0 ? maxCount : '0'
    }
  },
  methods: {
    onSubmit (evt) {
      const _this = this
      if (evt) {
        evt.preventDefault()
      }
      this.$validator.validateAll('fashionExpert')
        .then(isValid => {
          if (isValid) {
            _this.$store.commit('ui/showLoader')
            let params = {
              'firstname': this.form.firstName,
              'lastname': this.form.lastName,
              'email': this.form.email,
              'country': this.form.country,
              'message': this.form.message,
              'attachment': this.getAttachementToSend
            }
            if (!this.getAttachementToSend) {
              delete params.attachment
            }

            _this.$store.dispatch('storeLocator/sendFashionExpertRequest', params)
              .then(res => {
                _this.form = {
                  country: '',
                  firstName: '',
                  lastName: '',
                  email: '',
                  message: '',
                  attachments: [],
                  attachmentsInBase64: []
                }
                _this.$validator.reset()
                _this.openModalConfirmAppointment()
              })
              .catch(() => {
                toast.error(_this.$t('GenericError'))
              })
              .finally(() => {
                _this.$store.commit('ui/hideLoader')
              })
          }
        })
    },
    openModalConfirmAppointment () {
      const componentToLoad = import('theme/components/Modal/ConfirmAppointment')
      this.$store.dispatch('ui/showDynamicModalComponent', {
        component: componentToLoad,
        propsBootstrapVueModal: {
          'hide-footer': true,
          'hide-header': false,
          'modal-class': 'modal-confirm-appointment',
          centered: true
        },
        props: {
          title: this.$t('fashionExpert.confirmAppointmentTitle'),
          text: this.$t('fashionExpert.confirmAppointmentMessage')
        }
      })
    },
    getFileInBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async processFile (event) {
      if (event.target.files.length) {
        /* load only one attachment */
        this.form.attachments = [event.target.files[0]]
        /* load multiple attachments */
        // this.form.attachments.push(event.target.files[0])
        let promises = this.form.attachments.map(async (file) => {
          return {
            name: file.name,
            base64: await this.getFileInBase64(file)
              .then(res => {
                return res
              })
              .catch(error => {
                Logger.error(error)
              })
          }
        })
        this.form.attachmentsInBase64 = await Promise.all(promises)
      }
    },
    removeFile (indexFile) {
      this.form.attachments.splice(indexFile, 1)
      this.form.attachmentsInBase64.splice(indexFile, 1)
    },
    personalShopper () {
      // let settings = this.$store.getters['storeConfig/newsletterSettings']
      // if (settings && Object.keys(settings).length && settings.modal_media_url) {
      //   return settings.modal_media_url
      // } else {
      //   return require('theme/assets/image/img_modal_NL.jpg')
      // }
      if (process.client) {
        let img = require('theme/assets/image/PersonalShopperDESK.jpg')
        if (this.$store.state.ui.isMobile) {
          img = require('theme/assets/image/PersonalShopperMBL.jpg')
        }
        return img
      }
    }
  },
  components: {
    CustomerCareLink,
    BaseSelect,
    BaseInput,
    BaseTextarea
  }
}
</script>
