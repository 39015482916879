var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-care-pages fashion-expert-page"},[_c('b-row',{staticClass:"layout-with-shoulder"},[_c('b-col',{staticClass:"shoulder-left",attrs:{"cols":"12"}},[_c('div',{staticClass:"stiky-position"},[_c('customer-care-link')],1)]),_c('b-col',{staticClass:"shoulder-content",attrs:{"cols":"12"}},[_c('section',{staticClass:"fashion-expert"},[_c('no-ssr',[_c('header',{staticClass:"header"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('fashionExpert.title')))]),_c('div',{staticClass:"full-background"},[_c('img',{attrs:{"src":_vm.personalShopper()}})]),_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('fashionExpert.notice')))])])]),_c('section',{staticClass:"content"},[_c('b-form',{ref:"fashionExpertForm",attrs:{"data-vv-scope":"fashionExpert","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-row',{staticClass:"custom-row",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"pr-md-2 custom-col",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"groupFirstname"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[{'is-invalid': _vm.errors.has('fashionExpert.firstName')}, 'secondary'],attrs:{"type":"text","name":"firstName","label":_vm.$t('fashionExpert.firstName')+'*',"validations":[{
                        condition: _vm.errors.has('fashionExpert.firstName'),
                        text: _vm.errors.first('fashionExpert.firstName')
                    }]},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})],1)],1),_c('b-col',{staticClass:"pl-md-2 custom-col",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"groupLastname"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[{'is-invalid': _vm.errors.has('fashionExpert.lastName')}, 'secondary'],attrs:{"type":"text","name":"lastName","label":_vm.$t('fashionExpert.lastName')+'*',"validations":[{
                      condition: _vm.errors.has('fashionExpert.lastName'),
                      text: _vm.errors.first('fashionExpert.lastName')
                    }]},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1)],1)],1),_c('b-row',{staticClass:"custom-row",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"pr-md-2 custom-col",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"groupEmail"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],class:[{'is-invalid': _vm.errors.has('fashionExpert.email')}, 'secondary'],attrs:{"type":"email","name":"email","label":_vm.$t('fashionExpert.email')+'*',"validations":[{
                      condition: _vm.errors.has('fashionExpert.email'),
                      text: _vm.errors.first('fashionExpert.email')
                    }]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('b-col',{staticClass:"pl-md-2 custom-col",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"groupCountry"}},[_c('base-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[{'is-invalid': _vm.errors.has('fashionExpert.selectCountry')}, 'secondary'],attrs:{"name":"country","label":_vm.$t('fashionExpert.selectCountry')+'*',"options":_vm.getCountriesOptions,"clearable":false,"searchable":true,"validations":[{
                      condition: _vm.errors.has('fashionExpert.selectCountry'),
                      text: _vm.errors.first('fashionExpert.selectCountry')
                    }]},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1)],1)],1),_c('b-row',{staticClass:"custom-row",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"custom-col base-textarea-col",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"id":"groupMessage"}},[_c('base-textarea',{directives:[{name:"validate",rawName:"v-validate",value:({required: true, max: 230}),expression:"{required: true, max: 230}"}],attrs:{"name":"message","label":_vm.$t('fashionExpert.message')+'*',"rows":12,"validations":[{
                      condition: _vm.errors.has('fashionExpert.message'),
                      text: _vm.$t('fashionExpert.errorMessageTxt')
                    }]},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('div',{staticClass:"base-textarea-footer"},[_c('p',{staticClass:"privacy-policy d-none d-md-inline",domProps:{"innerHTML":_vm._s(_vm.getPrivacyPolicy)}}),_c('p',{staticClass:"total-chars"},[_vm._v(_vm._s(_vm.$t('fashionExpert.charactersAvailable'))+" "+_vm._s(_vm.totalcount)+" ")])])],1)],1)],1),_c('div',{staticClass:"form-footer"},[_c('b-form-group',{staticClass:"upload-file-label",attrs:{"id":"groupUploadFile"}},[_c('label',{staticClass:"mb-0 custom-file-upload btn btn-secondary"},[_c('input',{staticClass:"d-none",attrs:{"type":"file","accept":_vm.acceptFile},on:{"change":function($event){return _vm.processFile($event)}}}),_c('span',{staticClass:"upload-intro f-regular"},[_vm._v("\n                    "+_vm._s(_vm.$t('fashionExpert.attachFile'))+"\n                  ")])]),(_vm.form.attachments.length > 0)?_c('ul',{staticClass:"files-list-text list-group"},_vm._l((_vm.form.attachments),function(file,index){return _c('li',{key:file.lastModified,staticClass:"filename-list list-group-item"},[_c('button',{staticClass:"btn btn-none",on:{"click":function($event){return _vm.removeFile(index)}}},[_c('i',{staticClass:"icomoon-icon icon-x"})]),_c('span',{staticClass:"file-name"},[_vm._v(_vm._s(file.name))])])}),0):_vm._e(),_c('p',{staticClass:"upload-file-notice"},[_vm._v(_vm._s(_vm.$t('fashionExpert.uploadFileNotice')))])]),_c('p',{staticClass:"privacy-policy d-inline d-md-none",domProps:{"innerHTML":_vm._s(_vm.getPrivacyPolicy)}}),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('fashionExpert.send')))])],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }